<template>
  <LoadingSpinner v-if="isLoading" class="justify-center items-center"/>
  <div v-if="!isLoading && userData && sectionData">
    <div class="bg-gray-100">
      <div>
      </div>
      <div
        class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 bg-gray-100 lg:max-w-7xl"
      >
      </div>
    </div>
    <div class="relative min-h-screen bg-gray-100">
      <main v-if="(!userData.IsAccountPrivate || isLoggedIn)" class="py-10 pt-3">
        <div
          class="
            mt-8
            max-w-3xl
            mx-auto
            grid grid-cols-1
            gap-6
            sm:px-6
            lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3
          "
        >
          <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <div class="aspect-w-9 aspect-h-4 w-full rounded-md overflow-hidden relative">
              <img class="object-cover absolute top-0 left-0 h-full w-full" :src="heroImage" alt="" />
              <div class="absolute top-1/2 opacity-80 left-0 h-1/2 w-full bg-gradient-to-t from-black to-transparent">

              </div>
              <div class="absolute top-1/2 left-0 h-1/2 w-full z-20 flex p-6">
                <h1 class="text-4xl text-white font-bold mt-auto">{{ userData.ShowDisplayName ? userData.DisplayName : (userData.FirstName && userData.LastName ? userData.FirstName + " " + userData.LastName : "") }}</h1>
              </div>
            </div>

            <section aria-labelledby="applicant-information-title">
              <div class="bg-white shadow sm:rounded-lg">
                <div v-if="userData.About" class="px-4 py-5 sm:px-6 sm:pb:0 border-b">
                  <h2
                    id="applicant-information-title"
                    class="text-lg leading-6 font-medium text-gray-900 mb-0"
                  >
                    About 
            
                  </h2>
                </div>
                <div class="px-4 py-5 sm:px-6">
                  <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div v-if="userData.About" class="sm:col-span-2">
                      <p class="text-lg text-gray-600">
                        {{ userData.About }}
                      </p>
                    </div>
                    <div class="sm:col-span-2" v-if="otherImages">
                      <dt
                        v-if="otherImages.length > 0"
                        class="text-lg leading-6 font-medium text-gray-900 mb-6"
                      >
                        Photos
                      </dt>
                      <ul
                        role="list"
                        class="
                          mx-auto
                          list-none
                          grid grid-cols-2
                          gap-x-4 gap-y-8
                          sm:grid-cols-3 sm:gap-x-6
                          lg:grid-cols-4
                          xl:gap-x-8
                        "
                      >
                        <li class="relative" v-for="imageUrl in otherImages"
                        :key="imageUrl">
                          <div
                            class="
                              group
                              block
                              w-full
                              aspect-w-10 aspect-h-7
                              rounded-lg
                              bg-gray-100
                              focus-within:ring-2
                              focus-within:ring-offset-2
                              focus-within:ring-offset-gray-100
                              focus-within:ring-indigo-500
                              overflow-hidden
                            "
                          >
                            <img
                              :src="imageUrl"
                              alt=""
                              class="
                                object-cover
                                pointer-events-none
                                group-hover:opacity-75
                              "
                            />
                            <button
                              type="button"
                              v-on:click="
                                open = true;
                                vimeo = false;
                                youtube = false;
                                photo = true;
                                mediaData = imageUrl;
                              "
                              class="absolute inset-0 focus:outline-none"
                            >
                              <span class="sr-only"
                                >View details for IMG_4985.HEIC</span
                              >
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="sm:col-span-2" v-if="videos">
                      <dt
                        class="text-lg leading-6 font-medium text-gray-900 mb-6"
                      >
                        Videos
                      </dt>
                      <ul
                        role="list"
                        class="
                          mx-auto
                          list-none
                          grid grid-cols-2
                          gap-x-4 gap-y-8
                          sm:grid-cols-3 sm:gap-x-6
                          lg:grid-cols-4
                          xl:gap-x-8
                        "
                      >
                        <li class="relative" v-for="video in videos" :key="video.videoId">
                          <div
                            class="
                              group
                              block
                              w-full
                              aspect-w-10 aspect-h-7
                              rounded-lg
                              bg-gray-100
                              focus-within:ring-2
                              focus-within:ring-offset-2
                              focus-within:ring-offset-gray-100
                              focus-within:ring-indigo-500
                              overflow-hidden
                            "
                          >
                            <img
                              :src="video.thumbnail"
                              alt=""
                              class="
                                object-cover
                                pointer-events-none
                                group-hover:opacity-75
                              "
                            />
                            <div
                              class="
                                absolute
                                top-0
                                left-0
                                h-full
                                w-full
                                flex
                                bg-gray-900 bg-opacity-30
                              "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-12 w-12 my-auto mx-auto"
                                viewBox="0 0 20 20"
                                fill="#fff"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <button
                              type="button"
                              class="absolute inset-0 focus:outline-none"
                              v-on:click="
                                open = true;
                                vimeo = video.type == 'vimeo';
                                youtube = video.type == 'youtube';
                                photo = false;
                                mediaData = video.videoId;
                              "
                            >
                              <span class="sr-only"
                                >View details for IMG_4985.HEIC</span
                              >
                            </button>
                          </div>
                        </li>
                        <!-- <li class="relative">
                          <div
                            class="
                              group
                              block
                              w-full
                              aspect-w-10 aspect-h-7
                              rounded-lg
                              bg-gray-100
                              focus-within:ring-2
                              focus-within:ring-offset-2
                              focus-within:ring-offset-gray-100
                              focus-within:ring-indigo-500
                              overflow-hidden
                            "
                          >
                            <img
                              src="https://vumbnail.com/130061843.jpg"
                              alt=""
                              class="
                                object-cover
                                pointer-events-none
                                group-hover:opacity-75
                              "
                            />
                            <div
                              class="
                                absolute
                                top-0
                                left-0
                                h-full
                                w-full
                                flex
                                bg-gray-900 bg-opacity-30
                              "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-12 w-12 my-auto mx-auto"
                                viewBox="0 0 20 20"
                                fill="#fff"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <button
                              type="button"
                              class="absolute inset-0 focus:outline-none"
                              v-on:click="
                                open = true;
                                vimeo = true;
                                youtube = false;
                                photo = false;
                                mediaData = 'the id of the video will go here';
                              "
                            >
                              <span class="sr-only"
                                >View details for IMG_4985.HEIC</span
                              >
                            </button>
                          </div>
                        </li> -->
                      </ul>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>

          <section
            aria-labelledby="timeline-title"
            class="lg:col-start-3 lg:col-span-1"
          >
          <div class="-mb-14 sm:flex sm:items-end sm:space-x-5">
            <img
              class="h-24 w-24 mx-auto z-10 relative shadow-md rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-cover"
              :src="profileImage"
              alt=""
            />
        </div>
            <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
              <h2 id="timeline-title" class="text-lg font-medium text-gray-900">
                Details
              </h2>

              <!-- Activity Feed -->
              <div class="mt-6 flow-root">
                
                <dl class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                  <div v-if="userData.City && userData.Province && userData.Radius" class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">Location</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{userData.City}}, {{userData.Province}} <span class="text-gray-500">(+{{userData.Radius}}km)</span>
                    </dd>
                  </div>
                  <div v-if="allCategories" class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      Categories
                    </dt>
                    <dd class="mt-2 text-sm text-gray-900">
                      <span
                        v-for="category in allCategories"
                        v-bind:key="category"
                        class="
                          inline-flex
                          items-center
                          px-2.5
                          py-0.5
                          rounded-full
                          text-xs
                          font-medium
                          bg-gray-100
                          text-gray-800
                          mx-1 mb-1
                        "
                      >
                        {{ category }}
                      </span>
                      <!-- <span
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-0.5
                        rounded-full
                        text-xs
                        font-medium
                        bg-red-100
                        text-red-800 ml-1
                      "
                    >
                      Adult Rated
                    </span> -->
                    </dd>
                  </div>
                  <div v-if="userData.WebsiteUrl" class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">Website</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <a
                        :href="'http://' + userData.WebsiteUrl.replace('https://','').replace('http://','')" target="_blank"
                        class="
                          text-indigo-600
                          hover:text-indigo-800 hover:underline
                        "
                      >
                        {{ userData.WebsiteUrl }}
                      </a>
                    </dd>
                  </div>
                  <div v-if="userData.DisplayEmail" class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">
                      Email address
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <a
                        :href="'mailto:' + userData.Email"
                        class="
                          text-indigo-600
                          hover:text-indigo-800 hover:underline
                        "
                      >
                        {{ userData.Email }}
                      </a>
                    </dd>
                  </div>
                  <div v-if="userData.DisplayPhone" class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">Phone</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <a
                        :href="'tel:' + userData.Phone"
                        class="
                          text-indigo-600
                          hover:text-indigo-800 hover:underline
                        "
                      >
                        {{ userData.Phone }}
                      </a>
                    </dd>
                  </div>
                  <div v-if="userData.LowerHourlyRate" class="sm:col-span-2">
                    <dt class="text-sm font-medium text-gray-500">Price</dt>
                    <dd v-if="userData.IsFlatRate" class="mt-1 text-sm text-gray-900">
                      From ${{ userData.LowerHourlyRate }} <span v-if="userData.UpperHourlyRate">to ${{
                        userData.UpperHourlyRate
                      }} </span> flat rate
                    </dd>
                    <dd v-else class="mt-1 text-sm text-gray-900">
                      From ${{ userData.LowerHourlyRate }}/hour <span v-if="userData.UpperHourlyRate">to ${{
                        userData.UpperHourlyRate
                      }}/hour </span>
                    </dd>
                  </div>
                </dl>
              </div>
              <div class="mt-6 flex flex-col justify-stretch"></div>
            </div>
          </section>
        </div>
      </main>
    </div>
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="fixed z-50 inset-0 overflow-y-auto"
        @close="
          open = false;
          photo = false;
        "
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle
                md:w-2/4
                sm:w-full
              "
            >
              <button
                class="
                  absolute
                  right-0
                  -top-10
                  cursor-pointer
                  hover:text-gray-50
                "
                v-on:click="open = false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#fff"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div class="h-full overflow-hidden rounded-lg">
                <iframe
                  v-if="youtube"
                  width="100%"
                  height="435"
                  :src="'https://www.youtube.com/embed/' + mediaData"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div
                  v-if="vimeo"
                  style="padding: 56.25% 0 0 0; position: relative"
                >
                  <iframe
                    :src="'https://player.vimeo.com/video/' + mediaData +'?h=ca1a53be2c&byline=0&portrait=0'"
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                    "
                    frameborder="0"
                    allow="autoplay  ; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div v-if="photo">
                  <img
                    style="height: 75vh"
                    :src="mediaData"
                    alt=""
                    class="object-cover w-full"
                  />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!--<TransitionRoot as="template" :show="photoOpen">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="photoOpen = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <button v-on:click="photopen = true">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild> -->

    <!-- This element is to trick the browser into centering the modal contents. -->
    <!-- <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle md:w-2/4 sm:w-full ">
            <img
              src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
              alt=""
              class="
                object-cover  
                pointer-events-none
                group-hover:opacity-75
                w-full
              "
              style="max-height: 75vh;"
            />
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot> -->
  </div>
</template>

<script>
//import { ref } from "vue";
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  CheckIcon,
  SelectorIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/vue/solid";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner.vue"
import { OcContentService } from "../services/ocContentService";
import { NopService } from "../services/nopService";
import { UploadcareService } from "../services/uploadcareService";
import { CookieService } from '../services/cookieService';

// const people = [
//   { id: 1, name: "-- Please Select --" },
//   { id: 2, name: "Comedians" },
//   { id: 3, name: "DJs" },
//   { id: 4, name: "Dance" },
//   { id: 5, name: "Special Acts" },
//   { id: 6, name: "Actors" },
//   { id: 7, name: "Musicians" },
// ];
// const people2 = [
//   { id: 1, name: "-- Please Select --" },
//   { id: 2, name: "Stand Up Comedians" },
//   { id: 3, name: "Adult Rated" },
//   { id: 4, name: "Comedy Magicians" },
//   { id: 5, name: "Emcees" },
//   { id: 6, name: "Corporate Comedians" },
// ];

const profiles = [
  {
    name: "Jane Cooper",
    title: "Toronto, On",
    role: "Comedian",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "John Cooper",
    title: "Toronto, On",
    role: "Musician",
    email: "johncooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  {
    name: "Jane Cooper",
    title: "Toronto, On",
    role: "Comedian",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "John Cooper",
    title: "Toronto, On",
    role: "Musician",
    email: "johncooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  {
    name: "Jane Cooper",
    title: "Toronto, On",
    role: "Comedian",
    email: "janecooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
  },
  {
    name: "John Cooper",
    title: "Toronto, On",
    role: "Musician",
    email: "johncooper@example.com",
    telephone: "+1-202-555-0170",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  },
  // More people...
];

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    MailIcon,
    PhoneIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LoadingSpinner,
  },
  setup() {
    // const selected = ref(people[0]);
    const open = ref(false);
    const youtube = ref(false);
    const vimeo = ref(false);
    const photo = ref(false);
    const mediaData = ref("")
    return {
      // people,
      // people2,
      // selected,
      profiles,
      open,
      youtube,
      vimeo,
      photo,
      mediaData,
    };
  },
  data() {
    return {
      isLoading: false,
      sectionData: null,
      userData: null,
      username: this.$route.params.username,
      userId: this.$route.params.id,
      profileImage: null,
      heroImage: null,
      otherImages: null,
      allCategories: null,
      videos: null,
      isLoggedIn: false,
    };
  },
  async created() {
    //need to do this to check whether the nav bar needs to be updated
    if (!CookieService.getLoginCookie()) {
      this.emitter.emit("logged-in", false);
    }
    this.isLoading = true;
    let id = CookieService.getLoginCookie();
    await this.fetchOcData();
    await this.fetchUserData();
    this.isLoggedIn = id == this.userData.Id;
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Settings - Profile",
        "KrickersSectionContent"
      );
    },
    async fetchUserData() {
      this.userData = await NopService.GetCustomerDetailsByUsername(this.username);
      //get image data
      this.profileImage = UploadcareService.buildImageUrl(
        this.userData.ProfileImageUUID, "-/resize/x200/"
      );
      this.heroImage = UploadcareService.buildImageUrl(
        this.userData.HeroImageUUID, "-/resize/x1920/"
      );
      this.otherImages = await this.getImages(this.userData.OtherImagesUUID)
      //get category data
      this.allCategories = (
        this.userData.PrimaryCategory +
        "," +
        this.userData.Categories
      )
        .split(",")
        .filter((n) => n);

      //janky but whatever:
      if (this.allCategories.length == 1 && this.allCategories[0] == "null") {
        this.allCategories = undefined;
      }
      //get videos, if any
      let youtubeVideos = [];
      let vimeoVideos = [];
      if (this.userData.YoutubeVideoIds) {
        let youtubeData = this.userData.YoutubeVideoIds.split(";");
        youtubeVideos = await this.getVideos("youtube", youtubeData);
      }
      if (this.userData.VimeoVideoIds) {
        let vimeoData = this.userData.VimeoVideoIds.split(";");
        vimeoVideos = await this.getVideos("vimeo", vimeoData);
      }
      this.videos = youtubeVideos.concat(vimeoVideos);
      if (this.videos.length == 0) {
        this.videos = null;
      }
    },
    async getImages(imageUUIDs) {
      let uuids = imageUUIDs.split(',').filter(n => n)
      let urls = []
      for (var ind in uuids) {
        let url = UploadcareService.buildImageUrl(uuids[ind], "-/resize/x800/")
        urls.push(url)
      }
      return urls
    },
    async getVideos(type, videoData) {
      let res = [];
      for (let i = 0; i < videoData.length; i++) {
        let data = videoData[i];
        if (data) {
          if (type == "youtube") {
            res.push(await this.createVideoItem(type, data))
          }
          else if (type == "vimeo") {
            res.push(await this.createVideoItem(type, data))
          }
        }
      }
      return res
    },
    async createVideoItem(type, data) {
      let splitData = data.split(":");
      let videoId = splitData[0];
      let videoTitle = splitData[1];
      let thumbnail = ""
      if (type == "youtube") {
        thumbnail = "https://img.youtube.com/vi/" + videoId + "/0.jpg"
      }
      else if (type == "vimeo") {
        thumbnail = await this.getVimeoThumbnail(videoId);
      }
      return {
        type: type,
        title: videoTitle,
        videoId: videoId,
        thumbnail: thumbnail,
      }
    },
    async getVimeoThumbnail(videoId) {
      let api = "https://vimeo.com/api/v2/video/" + videoId + ".json";
      let resp = await axios.get(api);
      return resp.data[0].thumbnail_small;
    },
  },
};
</script>
